import CartButton from '../lib/cart-button';

export default {
  init(vars) {

    CartButton.init();

    const { modalManager } = vars.common

    // catering form

    $('form[data-ajax]')
      .on('submit', e => {
        const form = $(e.currentTarget);
        const subtotal = Number(form.data('subtotal')).toFixed(2);

        form.addClass('form--submitting');
        form.find('input[name=subtotal]').val(subtotal);

        if ( typeof dataLayer !== 'undefined' ) dataLayer.push({ event: 'CateringOrder', value: subtotal })

        $.getJSON({
          url: env.ajaxUrl,
          type: 'post',
          data: new FormData(e.currentTarget),
          cache: false,
          contentType: false,
          processData: false,          
        })
        .always(response => {
          form.removeClass('form--submitting');

          if ( response.success ) {
            // close form
            modalManager.close($('#modal-form'));
            // show success popup
            setTimeout(() => {
              $('#modal-catering-message').find('.alert-wrapper').empty().append($('<div class="alert success centered">').html(response.data.message));
              modalManager.open($('#modal-catering-message'));
            }, 300);
            // reset all inputs
            form.find('.cart-button').trigger('reset');
            // reset form
            form.trigger('reset');
          }
          else {
            // show error message
            form.find('.alert-wrapper').empty().append($('<div class="alert error">').html(response.data.message));
          }
        })

        return false;        
      })    

    /*

    // TESTING

    const $form = $('#catering-form');
      
    $form.find(':input[name=location]').val('Glebe');
    $form.find(':input[name=email]').val('asfd@asfd.ca');
    $form.find(':input[name=date]').val('2025-07-09T12:15');
    $form.find(':input[name=phone]').val('123-456-7890');
    $form.find(':input[name=address]').val('123 Fake Street');
    $form.find(':input[name=province]').val('ON');
    $form.find(':input[name=city]').val('Ottawa');
    $form.find(':input[name=country]').val('CA');

    $('a[data-modal]').trigger('click');

    /**/

  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
