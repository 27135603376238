import jQuery from 'jquery';
import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import taxMenu from './routes/taxMenu';
import catering from './routes/catering';
import contact from './routes/contact';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  taxMenu,
  catering,
  ordering: catering,
  serviceDeTraiteur: catering,
  contact,
  contactezNous: contact,
});

jQuery(() => {
  routes.loadEvents()
})
